import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation } from 'react-i18next';

const ValuePartners = ({
  title,
  subTitle,
  values,
  leftStar,
  rightStar
}) => {
  const breakpoints = useBreakpoint();
  const {t} = useTranslation();
  return (
    <div className="font-sans flex flex-col mx-auto w-full space-y-10 md:space-y-20">
      {
        leftStar && 
        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="absolute left-2 hidden md:block self-end"
        />
      }
      {
        rightStar && 
        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="absolute right-2 hidden md:block self-end"
        />
      }

      <div className="space-y-2 md:space-y-8">
        {
          title &&
          <div className={`text-3xl md:text-5xl font-semibold w-full text-center mx-auto md:w-5/6`}>
            {t(title)}
          </div>
        }
        {
          subTitle &&
          <div className={`font-medium text-sm md:text-xl text-center mx-auto w-10/12 md:w-full text-gray-500`}>
            {t(subTitle)}
          </div>
        }
      </div>
      <div className="flex flex-col lg:gap-4 md:gap-2 sm:gap-4 md:flex-row m-0 p-0 md:flex-wrap items-center justify-between">
        {
          values.map((value, index) => (
            <div
              key={index}
              className="flex flex-col items-start space-y-3 w-1/3 bg-white border-box rounded-xl shadow-lg p-4"
              style={{width: breakpoints.sm ? '100%': '32%'}}
            >
              <div className="text-md md:text-sm bg-yellow-500 rounded-sm px-2">
                  {value.label}
                </div>
              <div className="ml-0">
                <img
                  alt="values img"
                  src={value.image}
                  className="m-0"
                  style={{
                    height: '150px'
                  }}
                />
              </div>
              <div className="text-xl md:text-lg text-gray-400 text-yellow-500">
                  {value.program}
              </div>
              <div className="text-base md:text-base text-black">
                {value.name}
                <div className="text-xs md:text-xs text-gray-400 mt-0">
                  {value.location}
                </div>
              </div>
              
            </div>
          ))
        }
      </div>
    </div>
  )
};

export default ValuePartners;
