import React from "react";

import Ready from "../ready/ready";
import SearchBanner from "../top_banner/search-banner";
import Approach from "../human_approach/approach";
import ValuePartners from "../value/value_partners";
import SecondaryHeader from "../secondary-header";
import { secondaryMenu } from "../../constants";
import { useTranslation } from 'react-i18next';

const Etablissements = () => {
  const {t} = useTranslation();
  const values = [
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/HEC-paris.png",
      program: "Programme Grande École",
      name: "HEC Paris",
      location: "Paris",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/ESCP-business-school.png",
      program: "Programme Grande École",
      name: "ESCP BS",
      location: "Paris, Madrid, Berlin, Londres, Varsovie",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/emlyon-business-school.svg",
      program: "Programme Grande École",
      name: "emlyon BS",
      location: "Lyon, Saint-Etienne, Paris, Shanghai, Mumbai",
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/skema.png",
      program: "Programme Grande École",
      name: "SKEMA BS",
      location: "Paris, Lille Nice, Raleigh, Suzhou, Belo Horizonte, Le Cap",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/audencia.png",
      program: "Programme Grande École",
      name: "Audencia",
      location: "Nantes, Paris",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/IMT-business-school.png",
      program: "Programme Grande École",
      name: "Institut Mines Télécom BS",
      location: "Paris",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/universite-de-paris.svg",
      program: "Programme Grande École",
      name: "Universite de Paris",
      location: "Paris",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/france-universités.svg",
      program: "Programme Grande École",
      name: "France Unersites",
      location: "Paris,  Lyon, Nice, Marseille",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/emlyon-business-school.svg",
      program: "Programme Grande École",
      name: "Emlyon bussiness school",
      location: "Lyon, Saint-Etienne",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/universite-de-paris.svg",
      program: "Programme Grande École",
      name: "Universite de Paris",
      location: "Paris",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/france-universités.svg",
      program: "Programme Grande École",
      name: "France Unersites",
      location: "Paris,  Lyon, Nice, Marseille",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/emlyon-business-school.svg",
      program: "Programme Grande École",
      name: "Emlyon bussiness school",
      location: "Lyon, Saint-Etienne",
      
    },
  ];

  return (
    <div className={`font-sans`} style={{background: "rgb(255 255 253)"}}>
      <SecondaryHeader
        menu={secondaryMenu}
      />
      <SearchBanner
        pageTitle={t('partner_schools')}
        title="Un test pour 50 programmes"
        mainDropdown = {{
          name: 'Programmes',
          list: [
            {name: 'Programme 1'},
            {name: 'Programme 1'},
            {name: 'Programme 2'}
           ]
        }}
        secondaryDropdown = {{
          name: 'Ecoles',
          list: [
            {name: 'Ecole 1'},
            {name: 'Ecole 2'},
            {name: 'Ecole 3'}
           ]
        }}
      />

      <div className={`mx-auto w-11/12 lg:w-5/6 my-20`}>
        <ValuePartners
          title='Liste des écoles partenaires'
          values={values}
        />
        <div className="my-20">
          <Approach
            title="Vous êtes une école ?"
            description1="Devenez partenaire de Mereos et intégrez le TASEM© dans la liste des tests d’aptitude reconnus pour sélectionner vos candidats."
            imgSrc="https://d2lxkizvrhu4im.cloudfront.net/images/partnership.svg"
            button={{
              text: `${t('learn_more')}`,
              href: "/demo"
            }}
            
          />
        </div>
        <div className=" mx-auto my-20">
          <Ready
            title={t('ready_to_test_mereos')}
            description={t('discover_our_ready_to_use_solutions')}
            leftButton={{
              text: `${t('register')}`,
              href: "https://registration.mereos.eu/candidature/?id=1"
            }}
            rightButton={{
              text: t('learn_more'),
              href: ""
            }}
            image="https://d2lxkizvrhu4im.cloudfront.net/images/Market_launch.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Etablissements;
