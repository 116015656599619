import * as React from "react";

import Layout from "../../../components/layout/layout";
import Seo from "../../../components/seo";
import Etablissements from "../../../components/tasem-test/etablissements";

const Index = () => {
  return (
    <>
      <Seo
        title="Établissements acceptant le TASEM"
        description="Le test TASEM ouvre les portes à plusieurs grandes écoles de commerce et de management international, délivrant toutes des diplômes visés à bac+4 ou à bac+5."
      />
      <Layout>
        <Etablissements />  
      </Layout>
    </>
  )
}

export default Index;
